import React, { useEffect } from "react";
import CTABar2 from "components/cta_bar_2";
import AssetFinancingProcessSteps from "components/asset_financing_process_steps";
import LenderLogosSlider from "components/lender_logos_slider";
import ShortTestimonialSlider from "components/short_testimonial_slider";
import Layout from "components/layout";
import Header from "components/header";
import LeadCapture1 from "components/lead_capture_1";
import FsAnalytics from "components/fs_analytics";

export default function Template(data) {
  const { pageContext } = data;
  const partner = pageContext;

  useEffect(() => {
    window.Intercom("shutdown");
  }, []);

  return (
    <Layout headerOverride={<Header partner={partner} pbfs />}>
      <>
        <div id="header-area-space"></div>

        {/* Lead Capture */}
        <LeadCapture1
          bgOverride={pageContext.custom_background_img_path}
          partnerId={partner.partner}
        />

        {/* Finstead Analytics */}
        <FsAnalytics />

        {/* Lender Logo Slider */}
        <LenderLogosSlider />

        {/* CALL-US CTA Bar */}
        <CTABar2 />

        {/* ASSET FINANCE PROCESS  */}
        <AssetFinancingProcessSteps />

        {/* Short Tertimonial Slider */}
        <ShortTestimonialSlider />
      </>
    </Layout>
  );
}
