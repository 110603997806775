import React, { useState } from "react";
import axios from "axios";
import ScrollAnimation from "react-animate-on-scroll";
import KG2Analytics, { KG2FormSubmit } from "components/kg2_analytics";

const LeadCapture1 = ({ bgOverride, partnerId }) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errored, setErrored] = useState(false);

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [value, setValue] = useState();
  const [contactNumber, setContactNumber] = useState();
  const [partner, setPartner] = useState(partnerId);
  // const [loanType, setLoanType] = useState('business loan');
  const [title, setTitle] = useState();

  const submit = (e) => {
    e.preventDefault();

    // trigger form submit event for KG2 analytics
    // if(partner == 92){KG2FormSubmit()}

    setSubmitting(true);
    axios
      .post("https://prod-api.finstead.com.au/partners/lead-page-lead/", {
        asset_type: "business loan",
        asset_value: value,
        first_name: firstName,
        last_name: lastName,
        email_address: emailAddress,
        contact_number: contactNumber,
        partner_id: partner,
      })
      .then((response) => {
        setSubmitted(true);
        setSubmitting(false);
      })
      .catch((error) => {
        setErrored(true);
        setSubmitting(false);
      });
  };

  return (
    <>
      {/* KG2 -- Google Tag Manager / GTM Script and No Script */}
      {/* {partner == 92 && <KG2Analytics />} */}

      <section
        className="bg-common-dark"
        data-bg-image={bgOverride || `/img/slider/slider1-1.jpg`}
      >
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-6 col-12 pt-4">
              <div
                className="calculation-content margin-b-30r md--space-b-70r mt-4"
                style={{ color: "black" }}
              >
                <h2 className="title title-light" style={{ color: "white" }}>
                  Check your eligibility{" "}
                  <span className="color-primary"> In under 30 seconds</span>
                </h2>
                <ScrollAnimation animateIn="fadeInUp">
                  <p style={{ color: "white" }}>
                    Our expert team of financial specialists are only a moment
                    away. We're here to help! Check your eligibility now, in
                    under 30 seconds, at no cost to you.
                  </p>
                </ScrollAnimation>
                <ul style={{ color: "white" }}>
                  <ScrollAnimation animateIn="fadeInRight">
                    <li>One dedicated specialist from start to finish</li>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInRight" delay={150}>
                    <li>A comprehensive range of finance products</li>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInRight" delay={300}>
                    <li>Tailored finance solutions</li>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInRight" delay={450}>
                    <li>Ongoing finance support</li>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="fadeInRight" delay={700}>
                    <li>Market leading rates</li>
                  </ScrollAnimation>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <ScrollAnimation animateIn="fadeInRight">
                <div
                  className="calculation-loan margin-b-30r mt-5"
                  style={{
                    boxShadow: "2px 2px 0px 0px #424242",
                    border: "1px solid #e2e2e2",
                    maxWidth: "550px",
                    margin: "auto auto",
                    backgroundColor: "rgba(255,255,255,0.9)",
                    borderRadius: "15px",
                  }}
                >
                  <form id="loan-calculate-form" onSubmit={submit}>
                    <div>
                      <div className="w-100 text-center rounded">
                        <ScrollAnimation animateIn="fadeIn">
                          {errored ? (
                            <h3>Uh oh! There was an error.</h3>
                          ) : submitted ? (
                            <h3>Success!</h3>
                          ) : submitting ? (
                            <h3>Submitting your details...</h3>
                          ) : (
                            <p
                              className="alert alert-secondary rounded text-center"
                              style={{ fontSize: "0.9em" }}
                            >
                              Please enter your details below
                            </p>
                          )}
                        </ScrollAnimation>
                      </div>

                      {submitting ? (
                        <div className="text-center w-100">
                          Just a moment...
                        </div>
                      ) : errored ? (
                        <div className="text-center">
                          <i
                            className="fa fa-times-circle"
                            style={{
                              color: "black",
                              fontSize: "25px",
                              marginBottom: "15px",
                            }}
                          ></i>
                          <p className="lead" style={{ color: "black" }}>
                            Something went wrong when submitting your referral,
                            please refresh the page and try again.
                          </p>
                        </div>
                      ) : submitted ? (
                        <div className="text-center">
                          <i
                            className="fa fa-check-circle"
                            style={{
                              color: "black",
                              fontSize: "25px",
                              marginBottom: "15px",
                            }}
                          ></i>
                          <p
                            className="lead text-center"
                            style={{ color: "black" }}
                          >
                            You have successfully submitted a referral to
                            Finstead.
                          </p>
                          <p className="lead" style={{ color: "black" }}>
                            {title
                              ? `A Finstead broker dedicated to ${title} clients will be in touch with you shortly`
                              : "One of our brokers will contact you during the next business day"}
                          </p>
                        </div>
                      ) : (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  style={{
                                    padding: "7px 10px",
                                    height: "50px",
                                    color: "#D9973E",
                                    border: "1px solid #dedede",
                                    fontSize: "16px",
                                  }}
                                  placeholder="First Name"
                                  required
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                  disabled={submitting}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  style={{
                                    padding: "7px 10px",
                                    height: "50px",
                                    color: "#D9973E",
                                    border: "1px solid #dedede",
                                    fontSize: "16px",
                                  }}
                                  placeholder="Last Name"
                                  required
                                  value={lastName}
                                  onChange={(e) => setLastName(e.target.value)}
                                  disabled={submitting}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              style={{
                                padding: "7px 10px",
                                height: "50px",
                                color: "#D9973E",
                                border: "1px solid #dedede",
                                fontSize: "16px",
                              }}
                              placeholder="Mobile Phone"
                              required
                              value={contactNumber}
                              onChange={(e) => setContactNumber(e.target.value)}
                              disabled={submitting}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              style={{
                                padding: "7px 10px",
                                height: "50px",
                                color: "#D9973E",
                                border: "1px solid #dedede",
                                fontSize: "16px",
                              }}
                              placeholder="Email Address"
                              required
                              value={emailAddress}
                              onChange={(e) => setEmailAddress(e.target.value)}
                              disabled={submitting}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control"
                              style={{
                                padding: "7px 10px",
                                height: "50px",
                                color: "#D9973E",
                                border: "1px solid #dedede",
                                fontSize: "16px",
                              }}
                              placeholder="$ How much would you like?"
                              required
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                              disabled={submitting}
                            />
                          </div>

                          <button
                            type="submit"
                            className="btn-ghost-hybrid color-dark w-100 mt-1 mb-5"
                            style={{ textAlign: "center" }}
                            disabled={submitting}
                          >
                            Submit
                          </button>
                        </div>
                      )}
                      <div
                        className="text-center tc-text w-100 text-center"
                        style={{ fontSize: "0.7em" }}
                      >
                        <p className="text-small">
                          By submitting you agree to the{" "}
                          <a href="/privacy">Finstead Privacy Policy</a> and{" "}
                          <a href="/terms">Terms of Service</a>.
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LeadCapture1;
